<template>
  <div class="">
    <div class="head-bg">
      <div class="ruls" @click="showrule = true">规则</div>
      <van-image width="100vw" :src="detail.merge_image_bg" />
      <div class="meng"></div>
    </div>
    <div class="content">
      <div class="content-item" style="margin-top: 0">
        <div class="title">
          <div class="name">
            {{ detail.name }}
            <img :src="levelsChange(detail.levels)" alt="" />
          </div>
          <div class="num">限量合成{{ detail.number }}份</div>
        </div>
        <div class="cl-box">
          <div class="title"><img src="./img/cailiao1.png" alt="" /></div>
          <div class="tips-add">添加多把赤霄剑，可提高合成凌霄棍概率</div>
          <div class="add-hero">
            <div class="add-left hero" v-if="detail.items && detail.items.length">
              <div class="hero-item" v-for="(item, index) in detail.items" :key="index">
                <div class="box1 add-box-item">
                  <img :src="item.image_icon" alt="" />
                  <div class="num">{{ item.user_num }}</div>
                  <div class="item-meng" @click="heroFn" v-if="item.user_num < item.number"></div>
                  <div class="stepper">
                    <div :class="addnum == 10 || addnum < 10 ? 'gre' : ''" @click="addnumFn(1)">
                      <van-icon name="minus" />
                    </div>
                    <div class="num-box">{{ addnum }}</div>
                    <div :class="addnum == max ? 'gre' : ''" @click="addnumFn(2)">
                      <van-icon name="plus" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-right">
              <div class="line"></div>
              <div class="">
                <div class="add-r-item">
                  <div class="title">凌霄棍合成概率：</div>
                  <div class="num">
                    60% + <span>{{ shinum }}%</span>
                  </div>
                </div>

                <div class="add-r-item">
                  <div class="title">2000积分合成概率：</div>
                  <div class="num">
                    40% - <span class="red">{{ shinum }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-wrapper">
          <cpdBtn
            :time="detail.start_time"
            :end_time="detail.end_time"
            :hasAll="detail.hasAll"
            @finistTime="finistTime"
            :is_merge="detail.is_merge"
            @compoundHreo="compoundHreo"
            :server_time="server_time"
          />
        </div>

        <!-- <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>传说级藏品</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div> -->
      </div>

      <div class="content-item">
        <div class="sub-title">
          <div class="sub-title-box">
            <img src="./img/titleBg.png" alt="" />
            <div class="titleText">凌霄棍</div>
          </div>
        </div>
        <div class="sub-content ztSubContent">
          <div>
            叶辰从凌霄宝殿所获，乃支撑凌霄宝殿的顶梁柱，认主后能遂心如意，可长可短，可粗可细，上可捅九天，下可搅四海。
          </div>
        </div>
      </div>
      <div class="brigt">
        <div class="">
          <div class="t-c">
            <img class="wid237" :src="require('@/imgs/comm/Slice15hc.png')" alt="" />
          </div>
          <div class="t-c f-10 brigt-content c-07">限量合成{{ detail.number }}份</div>

          <div class="">
            <div class="flex-bt marg36">
              <img class="wid90" :src="require('@/imgs/comm/Slice20.png')" alt="" />
              <img class="wid90" :src="require('@/imgs/comm/Slice21.png')" alt="" />
            </div>
            <div class="flex-bt">
              <img class="wid90" :src="require('@/imgs/comm/Slice22.png')" alt="" />
              <img class="wid90" :src="require('@/imgs/comm/Slice23.png')" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="parameter f-12">
        <div class="">
          <div class="title t-c">
            <img class="wid237" :src="require('@/imgs/comm/Slice16.png')" alt="" />
          </div>
          <div class="bright-item">
            <span class="c-07">商品名称</span>
            <span> {{ productDetail.items_name }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品类型</span>
            <span> {{ productDetail.type_name }}</span>
          </div>

          <div class="bright-item">
            <span class="c-07">品牌方</span>
            <span> {{ productDetail.producter }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">发行方</span>
            <span> {{ productDetail.publisher }}</span>
          </div>
          <div class="bright-item" v-if="productDetail.publish_date">
            <span class="c-07">发行时间</span>
            <span> {{ timeChange(productDetail.publish_date, 'YYYY年MM月DD日') }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品编号</span>
            <span> {{ productDetail.series_num }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 规则说明 -->
    <van-action-sheet v-model:show="showrule" :round="false" style="background-color: #1c172a">
      <div class="header">
        <img src="@/imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="showrule = false" />
        <img src="@/imgs/comm/pic_tc_title.png" class="img-title" />
        <span>合成须知</span>
      </div>
      <div class="block"></div>
      <div class="content-van">
        <div class="nei">
          <div class="nei-title">合成时间：</div>
          2022年9月15日16:00 - 2022年9月16日15:59
          <br /><br />
          <div class="nei-title">合成材料：</div>
          赤霄剑 <br /><br />
          <div class="nei-title">合成规则：</div>

          1、10把「赤霄剑」有60%初始概率合成1份「凌霄棍」，每追加一把「赤霄剑」，合成「凌霄棍」概率可增加4%，追加至20把时，将100%合成获得「凌霄棍」；<br /><br />
          2、合成「凌霄棍」概率未到100%时导致合成失败，可获得2000积分，积分可在【我的】-【积分】里面查看；<br /><br />
          3、本次合成限时开放，单人不限合成次数。<br /><br />

          <p class="t-c">最终解释权归看漫所有</p>
        </div>
      </div>
    </van-action-sheet>
    <PageLoading :show="loading" />
    <compoundPop
      :compoundPopShow="compoundPopShow"
      :effect_img="effect_img"
      :addnum="addnum"
      :typeSwitch="typeSwitch"
      @close="closeCompound"
      @isCompound="isCompoundFa = true"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getmergeitems } from '@/service/home';
import { lingxiaogunstuff } from '@/service/compound';
import cpdBtn from '@/components/CpdBtn';
import compoundPop from './components/lxgCompoundPop.vue';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { Toast } from 'vant';
import { shareHelper } from '@/helpers';
import { domUtil } from '@/utils';
import { produceDetail } from '@/service/home';
export default {
  components: {
    cpdBtn,
    compoundPop,
    PageLoading
  },
  setup() {
    const state = reactive({
      showrule: false,
      detail: {},
      compoundPopShow: false,
      token_id: 0, // 选择的材料中的id
      effect_img: '',
      loading: true,
      server_time: 0,
      addnum: 10, //材料2 选择数量
      max: 0, //材料2最大数量
      shinum: 0,
      nvnum: 0,
      pointnum: 0,
      productDetail: {},
      isCompoundFa: false, //是否点击了合成，未点击关闭弹窗不用刷新接口
      typeSwitch: true // 自定义用于区分犼和灵鸟  false为犼 true为灵鸟
    });

    onMounted(async () => {
      finistTime();

      const shareConf = {
        title: '看漫数藏',
        content: '由看漫APP倾力打造，藏品有限，价值无限',
        image: '',
        link: window.location.origin + '/home', // app分享链接
        url: window.location.origin + '/home' // app分享链接
      };
      // 设置微信分享和app内部分享(shareInfo：为动态获取数据。如果是静态的分享，则直接在data初始化中设置)
      shareHelper.init(shareConf);
      // 设置index.html中的头部分享信息
      domUtil.setDomShareMeta(shareConf);

      // data.map(item => {
      //   if (item.id == 58) {
      //     state.productDetail = item;
      //   }
      // });
    });

    const closeCompound = () => {
      state.compoundPopShow = false;
      state.isCompoundFa && finistTime();
    };

    // 数量增加-减少
    const addnumFn = type => {
      if (type == 1) {
        // 减
        if (state.addnum < 10 || state.addnum == 10) {
          Toast('同材料不足');
        } else {
          state.addnum -= 1;
          state.shinum = (state.addnum - 10) * 4;
        }
      } else {
        // +
        if (state.addnum < state.max) {
          state.addnum += 1;
          state.shinum = (state.addnum - 10) * 4;
        } else {
          // let str = state.max < 20 ? '无可添加藏品' : '已添加最大数量';
          Toast('已添加最大数量');
        }
      }
    };

    const getProduceDetail = async collections_id => {
      const { data } = await produceDetail({ collections_id });
      data.length && (state.productDetail = data[0]);
    };
    const heroFn = async () => {
      Toast('该材料不足，快去购买材料吧～');
    };
    const finistTime = async () => {
      const { data, server_time } = await lingxiaogunstuff();
      state.isCompoundFa = false;
      state.detail = data;
      state.server_time = server_time;
      state.effect_img = data.effect_img;

      // 获取卡片信息
      getProduceDetail(data.collections_id);

      let merge_config_items = data.items,
        user_merge_config_items = data.user_stuff_list,
        arr = [],
        subitem,
        hasAll = true; // 材料是否充足;
      // 循环得出用户拥有数

      merge_config_items.map((item, index) => {
        if (user_merge_config_items.length == 0) {
          hasAll = false;
          console.log(11111);
          arr.push({ ...item, user_num: 0 });
        } else {
          for (let i = 0; i < user_merge_config_items.length; i++) {
            subitem = user_merge_config_items[i];

            if (item.token_id == subitem.token_id) {
              arr.push({ ...item, user_num: subitem.number });
              // 判断材料2 选择第一个
              // 材料1不足
              if (subitem.number < item.number) {
                hasAll = false;
              }

              break;
            }
            if (user_merge_config_items.length - 1 == i && item.token_id != subitem.token_id) {
              arr.push({ ...item, user_num: 0 });
              // 材料不足
              hasAll = false;

              break;
            }
          }
        }
      });

      state.detail.items = arr;

      state.max = arr[0].user_num < 20 ? arr[0].user_num : 20;
      // console.log(hasAll, arr, '....', Math.floor(arr[0].user_num));
      state.addnum = state.max;
      if (state.addnum > 9) {
        state.shinum = (state.addnum - 10) * 4;
      }

      state.detail = { ...state.detail, hasAll };
      state.loading = false;
    };
    // 合成按钮
    const compoundHreo = () => {
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const userNum = id => {
      return 1;
    };
    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('@/imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('@/imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('@/imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('@/imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('@/imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('@/imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      userNum,
      finistTime,
      compoundHreo,
      timeChange,
      heroFn,
      diffTime,
      getProduceDetail,
      addnumFn,
      closeCompound,
      icon_tc_gb: require('@/imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
.stepper {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  // margin: 10px auto;
  margin-top: 10px;
  align-items: center;

  .gre {
    color: #453d5b;
  }
  .num-box {
    width: 50px;
    height: 26px;
    background: #453d5b;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #a297bf;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 0 7px;
  }
}
.add-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tips-add {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  text-align: center;
}
.add-hero {
  margin-top: 16px;
  display: flex;
  align-items: center;

  .add-left {
    width: 50%;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .add-right {
    display: flex;
    align-items: center;
    .line {
      margin-right: 20px;
      width: 0px;
      height: 77px;

      border: 1px solid #453d5b;
    }
    .add-r-item {
      margin-bottom: 10px;
      .title {
        font-size: 12px;
        font-weight: 400;
      }
      .num {
        font-size: 16px;
        font-weight: 500;
        span {
          color: #3cdbe8;
        }
        .red {
          color: #e83c3c;
        }
      }
    }
  }
}
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content-van {
    background-color: $bc;
    color: $fc;
    font-size: 14px;

    .nei {
      margin-left: 16px;
      margin-right: 16px;

      color: rgba(255, 255, 255, 0.7);
      .nei-title {
        color: rgba(255, 255, 255, 1);
      }
      span {
        color: #3cdbe8;
      }
      p {
        margin: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.brigt-content {
  margin-bottom: 24px;
}
.head-bg {
  position: relative;
  .ruls {
    position: absolute;
    left: 0;
    bottom: 100px;
    z-index: 9;
    width: 64px;
    height: 24px;
    background: #58dedd;
    font-size: 12px;
    color: #000000;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 500;
    // justify-content: center;
    align-items: center;
  }
}
.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../../imgs/comm/Slice9.png');
  margin-bottom: 24px;
}
.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
.hc-btn {
  background: url('../../imgs/comm/hcbtn.png') no-repeat;
  background-size: 100% 100%;
  width: 164px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #20322c;
}
.meng {
  width: 100vw;
  height: 160px;
  position: absolute;
  // top: -160px;
  bottom: 0;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
}
.content {
  padding: 0 16px 10px;
  position: relative;
  top: -50px;
}
.add {
  font-size: 24px;
  text-align: center;
  // margin: 4px 0;
}
.content-item2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    width: 100%;
    margin-top: 6px;
    img {
      width: 237px;
      margin: 0 auto;
    }
  }
}
.content-item {
  background: #2f293f;
  padding: 34px 11px 10px;
  margin-bottom: 36px;
  margin-top: 36px;
  position: relative;
  .sub-title {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;

    // .title-img {
    //   width: 100%;
    //   height: 50px;
    // }
    .sub-title-box {
      font-size: 0px;
      position: relative;

      img {
        width: 192.5px;
        height: 36px;
      }

      .titleText {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }
  .sub-content {
    // margin-top: 34px;
    font-size: 12px;

    color: rgba(255, 255, 255, 0.7);
    span {
      color: rgba(255, 255, 255, 1);
      font-weight: bold;
    }
    p {
      margin: 2px 0;
    }
    div {
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
  .ztSubContent {
    div {
      margin-bottom: 0;
      &:nth-child(1) {
        margin-bottom: 0;
      }
    }
    &:last-child {
      margin-bottom: 10px;
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    .name {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    img {
      width: 50px;
      margin-left: 5px;
    }
    .num {
      background: #3cdbe8;
      color: #2f293f;
      width: 85px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      span {
        font-weight: 500;
      }
    }
  }
  .cl-box {
    background: #1c172a;
    padding: 0 5px 10px 5px;
    margin-top: 18px;
    .title {
      text-align: center;
      font-size: 0px;
      padding: 5px 0;
      img {
        width: 237px;

        margin: 0 auto;
      }
    }
    .hero-wrapper {
      display: flex;
      justify-content: center;
    }
    .hero1 {
      padding: 0 27px;
    }
    .hero {
      display: flex;
      flex-wrap: wrap;
      font-size: 0px;
      align-items: center;
      justify-content: space-around;
      //justify-content: space-between;
      img {
        width: 70px;
      }
      .mr10 {
        margin-right: 10px;
      }
      .choose {
        border: 1px solid #5fdfd3;
        border-radius: 4px;
        background-color: #2f293f;
      }
      .nochoose {
        border: 1px solid #1c172a;
        border-radius: 4px;
      }
      // .w33 {
      //   width: 33%;
      // }
      .hero-item {
        width: 33%;
        // position: relative;

        .box1 {
          // margin-right: 10px;
          // &:nth-child(3n + 4) {
          //   margin-right: 0px;
          // }
          position: relative;
          margin: 0 auto;
          text-align: center;
        }
        .martop14 {
          margin-top: 14px;
        }
        .num {
          position: absolute;
          right: 9px;
          top: 9px;
          background: #2f293f;
          font-size: 12px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          z-index: 2;
        }
        .neednum {
          font-size: 12px;
          text-align: center;
          span {
            color: $primary;
          }
        }
        .item-meng {
          width: 69px;
          height: 73px;
          background: #2f293f;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
